





















































































import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { request, PagedResults, ReportedQuestionInfo, TableQuery, TableState, ReportedLessonInfo, Api, ReportedListInfo, ReportedListWordInfo } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import ModalMixin from '@/edshed-common/mixins/ModalMixin'

@Component
export default class ReportedQuestions extends Mixins(ModalMixin, ComponentHelper) {
  private isLoading: boolean = false

  private reportedContent: PagedResults<ReportedQuestionInfo> | PagedResults<ReportedLessonInfo> | PagedResults<ReportedListInfo> | PagedResults<ReportedListWordInfo> = { items: [], total: 0 }
  private currentTab = 0

  private tableState: TableState = {
    page: 1,
    perPage: 10,
    sort: 'created',
    dir: 'asc',
    term: ''
  }

  public mounted () {
    if (this.$route.query.tab && typeof this.$route.query.tab === 'string') {
      const tab = parseInt(this.$route.query.tab)
      this.getData(tab)
    } else {
      this.getData(0)
    }
  }

  get currentTitle () {
    switch (this.currentTab) {
      case 0:
        return 'Reported Quizzes'
      case 1:
        return 'Reported Questions'
      case 2:
        return 'Reported Lessons'
      case 3:
        return 'Reported Lesson Episodes'
      case 4:
        return 'Reported Spelling Lists'
      case 5:
        return 'Reported Spelling Words'
      default:
        return 'Reported Content'
    }
  }

  public getTitle (report: ReportedQuestionInfo | ReportedLessonInfo | ReportedListInfo | ReportedListWordInfo) {
    switch (this.currentTab) {
      case 0:
        return (report as ReportedQuestionInfo).question_set_title
      case 1:
      // eslint-disable-next-line no-case-declarations
        const _report = report as ReportedQuestionInfo
        return _report.question
          ? JSON.parse(_report.question).value
          : ''
      case 2:
        return (report as ReportedLessonInfo).lesson_title
      case 3:
        return (report as ReportedLessonInfo).lesson_episode_title
      case 4:
        return (report as ReportedListInfo).list_title
      case 5:
        return (report as ReportedListWordInfo).text
      default:
        return 'Reported Content'
    }
  }

  public getIdentField () {
    switch (this.currentTab) {
      case 0:
        return 'question_set_ident'
      case 1:
        return 'question_set_ident'
      case 2:
        return 'lesson_ident'
      case 3:
        return 'lesson_episode_ident'
      case 4:
        return 'list_ident'
      case 5:
        return 'list_word_ident'
      default:
        return 'Reported Content'
    }
  }

  public async getData (tab: number = this.currentTab) {
    console.log('here')
    const method = this.getApiMethod(tab)
    const query: TableQuery = {
      skip: (this.tableState.page - 1) * this.tableState.perPage,
      take: this.tableState.perPage,
      sort: this.tableState.sort,
      dir: this.tableState.dir,
      term: this.tableState.term
    }
    try {
      const res = await Api[method](query)
      // const res = await method(query)
      if (res) {
        this.reportedContent = res
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        this.alert({
          title: 'Error',
          message: error.message
        })
      }
    }
  }

  public getApiMethod (tab) {
    switch (tab) {
      case 0:
        return 'getReportedQuestionSets'
      case 1:
        return 'getReportedQuestions'
      case 2:
        return 'getReportedLessons'
      case 3:
        return 'getReportedLessonEpisodes'
      case 4:
        return 'getReportedLists'
      case 5:
        return 'getReportedListWords'
      default:
        return 'getReportedQuestionSets'
    }
  }

  public pageChanged (page: number) {
    this.tableState.page = page
    this.getData()
  }

  public tabChanged () {
    this.tableState.page = 1
    this.getData()
  }

  getLinkForRow (row: ReportedQuestionInfo | ReportedLessonInfo | ReportedListInfo | ReportedListWordInfo) {
    switch (this.currentTab) {
      case 0:
        return this.config.targetInfo.quiz + 'browse/' + (row as ReportedQuestionInfo).set_ident
      case 1:
        return this.config.targetInfo.quiz + 'browse/' + (row as ReportedQuestionInfo).set_ident + '#' + (row as ReportedQuestionInfo).question_id
      case 2:
        return this.config.targetInfo.quiz + 'lessons/' + (row as ReportedLessonInfo).lesson_ident
      case 3:
        return this.config.targetInfo.quiz + 'lessons/' + (row as ReportedLessonInfo).lesson_ident + '#' + (row as ReportedLessonInfo).lesson_episode_id
      case 4:
        return this.config.targetInfo.auth + 'lists/' + (row as ReportedListInfo).list_ident
      case 5:
        return this.config.targetInfo.auth + 'lists/' + (row as ReportedListWordInfo).list_ident
      default:
        return Api.getReportedQuestionSets
    }
  }

  closeReport (reportId: number) {
    if (this.currentTab === 0 || this.currentTab === 1) {
      this.closeReportedQuestion(reportId)
    } else if (this.currentTab === 2 || this.currentTab === 3) {
      this.closeReportedLesson(reportId)
    } else {
      this.closeReportedList(reportId)
    }
  }

  deleteContent (row: ReportedQuestionInfo | ReportedLessonInfo | ReportedListInfo | ReportedListWordInfo) {
    if (this.currentTab === 0 || this.currentTab === 1) {
      throw new Error('Action not supported')
    } else if (this.currentTab === 2 || this.currentTab === 3) {
      throw new Error('Action not supported')
    } else if (this.currentTab === 4) {
      this.confirmDeleteList(row as ReportedListInfo)
    } else if (this.currentTab === 5) {
      this.confirmDeleteListWord(row as ReportedListWordInfo)
    }
  }

  confirmDeleteList (row: ReportedListInfo) {
    this.$buefy.dialog.confirm({
      title: 'Delete List',
      message: `Are you sure you wish to delete list "${row.list_title}"? This action cannot be undone.`,
      confirmText: 'Delete',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => {
        await Api.deleteList(row.list_ident)
        await this.getData()
      }
    })
  }

  confirmDeleteListWord (row: ReportedListWordInfo) {
    this.$buefy.dialog.confirm({
      title: 'Remove Word',
      message: `Are you sure you wish to remove word "${row.dictionary.word}" from list "${row.list_title}"? This action cannot be undone.`,
      confirmText: 'Delete',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        Api.deleteWordFromList(row.list_ident, row.list_word_ident)
      }
    })
  }

  forwardReport (reportId: number) {
    this.$buefy.dialog.confirm({
      title: 'Forward to school',
      message: 'An email will be sent to all relevant staff in user\'s school.\nThis report will also be closed.',
      confirmText: 'Forward',
      type: 'is-warning',
      hasIcon: true,
      onConfirm: () => {
        if (this.currentTab === 0 || this.currentTab === 1) {
          this.forwardReportedQuestion(reportId)
        } else if (this.currentTab === 2 || this.currentTab === 3) {
          this.forwardReportedLesson(reportId)
        }
      }
    })
  }

  closeReportedQuestion (reportId: number) {
    request('PATCH', 'quiz/reported/' + reportId + '/close', null)
      .then((_response) => {
        this.getData()
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }

        /// this.response = 'Details incorrect'
      })
  }

  closeReportedLesson (reportId: number) {
    request('PATCH', 'lesson/reported/' + reportId + '/close', null)
      .then((_response) => {
        this.getData()
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }

        /// this.response = 'Details incorrect'
      })
  }

  async closeReportedList (reportId: number) {
    try {
      await Api.closeListReport(reportId)
      this.getData()
    } catch (err) {

    }
  }

  forwardReportedQuestion (reportId: number) {
    request('PATCH', 'quiz/reported/' + reportId + '/flag', null)
      .then((_response) => {
        this.getData()
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }

        /// this.response = 'Details incorrect'
      })
  }

  forwardReportedLesson (reportId: number) {
    request('PATCH', 'lesson/reported/' + reportId + '/flag', null)
      .then((_response) => {
        this.getData()
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }

        /// this.response = 'Details incorrect'
      })
  }
}
